<!-- 开屏页 -->
<template>
  <div class="start">
    <div v-show="palyShow" class="palyBtn" @click="palyVideo()"></div>
    <video ref="myVideo" id="myVideo" muted controlslist="nodownload" x5-video-player-fullscreen="true"
      webkit-playsinline="true" x-webkit-airplay="true" playsinline="true" x5-playsinlin>
      <source :src="videoSrc" type="video/mp4" />
    </video>
    <!-- <img src="./image/绍兴开屏页_00000_iSpt.gif" alt="" /> -->
    <div v-show="startShow" class="enterBtn" @click="toIndex()">开始体验</div>
    <div class="tioaguo" v-show="tShow" @click="toIndex()">跳过</div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      videoSrc: require("./image/2.mp4"),
      startShow: false,
      palyShow: true,
      tShow: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    toIndex() {
      this.$router.replace("/index");
    },
    palyVideo() {
      this.palyShow = false;
      this.$refs.myVideo.play();
    },
  },
  mounted() {
    let that = this;
    //--创建页面监听，等待微信端页面加载完毕 触发音频播放
    // document.addEventListener(
    //   "WeixinJSBridgeReady",
    //   function () {
    //     document.getElementById("myVideo").play();
    //   },
    //   false
    // );
    //--创建触摸监听，当浏览器打开页面时，触摸屏幕触发事件，进行音频播放
    // document.addEventListener(
    //   "touchstart",
    //   function () {
    //     document.getElementById("myVideo").play();
    //     document.getElementById("myVideo").pause();
    //     setTimeout(function () {
    //       document.getElementById("myVideo").play();
    //     }, 200);
    //     that.startShow = false;
    //   },
    //   true
    // );
    this.$refs.myVideo.addEventListener(
      "play",
      function () {
        //结束
        that.tShow = true;
      },
      false
    );
    this.$refs.myVideo.addEventListener(
      "ended",
      function () {
        //结束
        that.toIndex();
        that.tShow = false;
      },
      false
    );
  },
};
</script>
<style lang="less">
.start {
  width: 100%;
  height: 100vh;
  position: relative;

  .palyBtn {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: url("./image/20220411103533.jpg") no-repeat;
    background-size: 100%;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: fill;

    source {
      width: 100%;
      height: 100%;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  .enterBtn {
    width: 17.6rem;
    height: 4.8rem;
    position: fixed;
    bottom: 7.2rem;
    left: 50%;
    margin-left: -8.8rem;
    z-index: 100;
    background: url("./image/Group 555 (1).svg") no-repeat;
    background-size: 100%;
    font-size: 1.4rem;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #fff;
    line-height: 4.8rem;
    text-align: center;
    letter-spacing: 0rem;
  }

  .tioaguo {
    width: 5rem;
    height: 2.5rem;
    background: #000;
    opacity: 0.6;
    color: #fff;
    text-align: center;
    line-height: 2.5rem;
    position: absolute;
    right: 3rem;
    top: 3rem;
    border-radius: 0.5rem;
  }
}
</style>